$black-footer: #2E2E2E;
$yellow-opacity: #e6b41e33;
$mobile: 480px;
$tablet: 768px;
$small-screens: 992px;
$large-screens: 1200px;

:where(.css-dev-only-do-not-override-1i9hnpv) .ant-image-preview-operations-progress {
    display: none !important;
}

.class-for-actors {
    @media screen and (max-width: $small-screens) {
        height: 400px;
    }
    @media screen and (max-width: $tablet) {
        height: 540px;
    }
    @media screen and (max-width: 600px) {
        height: 560px;
    }
    @media screen and (max-width: 510px) {
        height: 670px;
    }
    @media screen and (max-width: $mobile) {
        height: 560px;
    }
    @media screen and (max-width: 450px) {
        height: 580px;
    }
    @media screen and (max-width: 415px) {
        height: 600px;
    }
}

.actors-box {
    .review {
        border-radius: 10px;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        display: flex !important;
        flex-direction: row;
        overflow: hidden;
        margin-bottom: 20px;
        color: var(--white);
        background-color: $black-footer;
        padding: 10px;
        &:nth-last-child(1) {
            margin-bottom: 0;
        }
        .user {
            width: 5%;
            height: 100px;
            @media screen and (max-width: $mobile) {
                width: 10%;
                height: 70px;
            }
            img {
                border-radius: 20%;
                background-color: var(--yellow);
                aspect-ratio: 4 / 4;
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
        .commit {
            width: 95%;
            padding: 0 10px;
            .info {
                display: flex;
                flex-direction: row;
                margin-bottom: 10px;
                p {
                    font-size: 14px;
                    opacity: .8;
                }
            }
            .content {
                font-size: 14px;
                display: inline;
                margin-right: 4px;
            }
            .read-more {
                font-size: 14px;
                opacity: .8;
                cursor: pointer;
                display: inline;
            }
        }
    }
    .no-review {
        width: 100%;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: var(--white);
        .icon {
            font-size: 3rem;
            color: var(--yellow);
            font-weight: 500;
        }
    }
}