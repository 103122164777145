.image-load {
    width: 100%;
    height: fit-content;
    background-color: rgba(0, 0, 0, 0.06);
    &::before {
        content: "";
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.06) 25%, rgba(0, 0, 0, 0.10) 37%, rgba(0, 0, 0, 0.06) 63%);
        display: block;
        animation: 1200ms skeleton linear infinite;
        animation-timing-function: ease;
    }
}