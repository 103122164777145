.load-home-movie {
    width: 80%;
    height: fit-content;
    margin: 40px auto 0 auto;
    @media screen and (max-width: "1200px") {
        width: 90%;
        margin: 40px auto 0 auto;
    }
    @media screen and (max-width: "992px") {
        width: 90%;
        margin: 0 auto;
    }
    @media screen and (max-width: "480px") {
        height: fit-content;
    }
    .title {
        width: 100%;
        height: 48px;
        margin: 20px 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .big-text {
            border-radius: 10px;
            overflow: hidden;
            width: 200px;
            height: 38px;
            background-color: rgba(0, 0, 0, 0.06);
            &::before {
                content: "";
                width: 100%;
                height: 100%;
                background: linear-gradient(90deg, rgba(0, 0, 0, 0.06) 25%, rgba(0, 0, 0, 0.10) 37%, rgba(0, 0, 0, 0.06) 63%);
                display: block;
                animation: 1200ms skeleton linear infinite;
                animation-timing-function: ease;
            }
            
        }
        ul{
            display: flex;
            flex-direction: row;
            gap: 10px;
            li {
                border-radius: 10px;
                overflow: hidden;
                width: 38px;
                height: 38px;
                list-style: none;
                background-color: rgba(0, 0, 0, 0.06);
                &::before {
                    content: "";
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(90deg, rgba(0, 0, 0, 0.06) 25%, rgba(0, 0, 0, 0.10) 37%, rgba(0, 0, 0, 0.06) 63%);
                    display: block;
                    animation: 1200ms skeleton linear infinite;
                    animation-timing-function: ease;
                }
            }
        }
    }
    .photo-box {
        width: 100%;
        height: 300px;
        @media screen and (max-width: "480px") {
            height: fit-content;
        }
        ul {
            display: flex;
            flex-direction: row;
            gap: 20px;
            width: 100%;
            height: 100%;
            display: grid;
            grid-template-columns: auto auto auto auto;
            @media screen and (max-width: 480px) {
                display: grid;
                grid-template-columns: auto auto;
            }
            li {
                border-radius: 10px;
                overflow: hidden;
                width: 100%;
                height: 100%;
                list-style: none;
                background-color: rgba(0, 0, 0, 0.06);            
                @media screen and (max-width: "992px") {
                    height: 100%;
                }
                @media screen and (max-width: "480px") {
                    width: 100%;
                    height: 250px;
                }
                &::before {
                    content: "";
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(90deg, rgba(0, 0, 0, 0.06) 25%, rgba(0, 0, 0, 0.10) 37%, rgba(0, 0, 0, 0.06) 63%);
                    display: block;
                    animation: 1200ms skeleton linear infinite;
                    animation-timing-function: ease;
                }
            }
        }
    }
    .load {
        border-radius: 10px;
        overflow: hidden;
        width: 100%;
        height: 48px;
        background-color: rgba(0, 0, 0, 0.06);
        margin-top: 30px;
        &::before {
            content: "";
            width: 100%;
            height: 100%;
            background: linear-gradient(90deg, rgba(0, 0, 0, 0.06) 25%, rgba(0, 0, 0, 0.10) 37%, rgba(0, 0, 0, 0.06) 63%);
            display: block;
            animation: 1200ms skeleton linear infinite;
            animation-timing-function: ease;
        }
    }
}