.load-slider {
    margin-top: 80px;
    width: 100%;
    height: 500px;
    background-color: rgba(0, 0, 0, 0.04);
    display: flex;
    align-items: flex-end;
    overflow: hidden;
    @media screen and (max-width: "992px") {
        height: 400px;
    }
    @media screen and (max-width: "768px") {
        height: 300px;
    }
    @media screen and (max-width: "480px") {
        height: 200px;
    }
    .box {
        width: 60%;
        height: 200px;
        @media screen and (max-width: "768px") {
            width: 70%;
            height: 130px;
        }
        @media screen and (max-width: "480px") {
            width: 100%;
            height: 100px;
        }
        .arrows {
            display: flex;
            justify-content: space-between;
            .back-and-next {
                display: flex;
                flex-direction: row;
                .icon {
                    border-radius: 10px;
                    overflow: hidden;
                    width: 42px;
                    height: 42px;
                    background-color: rgba(0, 0, 0, 0.06);
                    margin-left: 10px;
                    &::before {
                        content: "";
                        width: 100%;
                        height: 100%;
                        background: linear-gradient(90deg, rgba(0, 0, 0, 0.06) 25%, rgba(0, 0, 0, 0.10) 37%, rgba(0, 0, 0, 0.06) 63%);
                        display: block;
                        animation: 1200ms skeleton linear infinite;
                        animation-timing-function: ease;
                    }
                }
            }
            .play-icon {
                display: flex;
                flex-direction: row;
                .play {
                    border-radius: 10px;
                    overflow: hidden;
                    width: 120px;
                    height: 42px;
                    background-color: rgba(0, 0, 0, 0.06);
                    &::before {
                        content: "";
                        width: 100%;
                        height: 100%;
                        background: linear-gradient(90deg, rgba(0, 0, 0, 0.06) 25%, rgba(0, 0, 0, 0.10) 37%, rgba(0, 0, 0, 0.06) 63%);
                        display: block;
                        animation: 1200ms skeleton linear infinite;
                        animation-timing-function: ease;
                    }
                }
                .icon {
                    border-radius: 10px;
                    overflow: hidden;
                    width: 42px;
                    height: 42px;
                    background-color: rgba(0, 0, 0, 0.06);
                    margin-left: 10px;
                    &::before {
                        content: "";
                        width: 100%;
                        height: 100%;
                        background: linear-gradient(90deg, rgba(0, 0, 0, 0.06) 25%, rgba(0, 0, 0, 0.10) 37%, rgba(0, 0, 0, 0.06) 63%);
                        display: block;
                        animation: 1200ms skeleton linear infinite;
                        animation-timing-function: ease;
                    }
                }
            }
        }
        .info-box {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            overflow: hidden;
            height: 100%;
            width: 100%;
            margin-top: 10px;
            background-color: rgba(0, 0, 0, 0.06);
            @media screen and (max-width: "480px") {
                border-radius: 0;
            }
            &::before {
                content: "";
                width: 100%;
                height: 100%;
                background: linear-gradient(90deg, rgba(0, 0, 0, 0.06) 25%, rgba(0, 0, 0, 0.10) 37%, rgba(0, 0, 0, 0.06) 63%);
                display: block;
                animation: 1200ms skeleton linear infinite;
                animation-timing-function: ease;
            }
        }
    }
}