$mobile: 480px;
$tablet: 768px;
$small-screens: 992px;
$large-screens: 1200px;

footer {
  box-sizing: border-box;
  position: relative;
  top: 30px;
  width: 100%;
  height: 100%;
  color: var(--white);
  background-color: #2e2e2e;
  padding: 20px 0;
  .infoFooterTop {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media screen and (max-width: $mobile) {
      display: flex;
      flex-direction: column;
      padding: 0 20px;
    }
    ul {
      li {
        margin-bottom: 10px;
        list-style: none;
        .title {
          @media screen and (max-width: $mobile) {
            margin-top: 20px;
          }
        }
        p {
          margin-bottom: 10px;
          span {
            font-weight: 600;
          }
        }
        a {
          color: white;
          font-weight: 300;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  .infoFooterBottom {
    margin-top: 20px;
    font-weight: 300;
    @media screen and (max-width: $mobile) {
      padding: 0 20px;
    }
    p {
      margin-bottom: 10px;
      span {
        position: relative;
        top: 1px;
        color: var(--yellow);
        a {
          text-decoration: none;
          color: var(--yellow);
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.termsBtn {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
