$white: #fff;
$black: #343434;
$yellow-opacity: #e6b41e72;
$mobile: 480px;
$tablet: 768px;
$small-screens: 992px;
$large-screens: 1200px;

.bg-image {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    .bg-color {
        background-color: $yellow-opacity;
        backdrop-filter: blur(5px);
        .container {
            .actor-box {
                display: flex;
                flex-direction: row;
                gap: 20px;
                color: $white;
                @media screen and (max-width: $tablet) {
                    display: flex;
                    flex-direction: column;
                }
                .left {
                    box-sizing: border-box;
                    padding: 20px 0 20px 0;
                    width: 25%;
                    height: 100%;

                    @media screen and (max-width: $tablet) {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 20px 0 0 0;
                    }
                    @media screen and (max-width: $mobile) {
                        padding: 20px 20px 0 20px;
                    }
                    .ant-image {
                        width: 100% !important;
                        @media screen and (max-width: $tablet) {
                            width: 50% !important;
                        }
                        @media screen and (max-width: $mobile) {
                            width: 100% !important;
                        }
                        img {
                            aspect-ratio: 10 / 16;
                            border-radius: 10px !important;
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
                .right {
                    box-sizing: border-box;
                    padding: 20px 0 20px 0;
                    width: 80%;
                    @media screen and (max-width: $tablet) {
                        width: 100%;
                        padding: 0 0 20px 0;
                    }

                    @media screen and (max-width: $mobile) {
                        width: 100%;
                        padding: 0 20px 20px;
                    }
                    .birthday {
                        border-radius: 4px;
                        width: fit-content;
                        background-color: var(--yellow);
                        padding: 2px;
                        margin-bottom: 5px;
                    }
                }
            }
        }
    }

}