$second-black: #2e2e2e71;
$yellow: #e6b31e;
$yellow-opacity: #e6b41e33;
$mobile: 480px;
$tablet: 768px;
$small-screens: 992px;
$large-screens: 1200px;

.pagination {
  margin: 10px 0 0 0;
  width: 100%;
  .ant-pagination {
    display: flex;
    justify-content: center;
  }
}

:where(.css-dev-only-do-not-override-1i9hnpv).ant-spin {
  color: var(--white);
}

.load-more {
  border-radius: 10px;
  width: 100%;
  height: 40px;
  border: none;
  padding: 10px;
  background-color: var(--yellow);
  color: var(--white);
  text-transform: uppercase;
  cursor: pointer;
  transition: 200ms;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: $mobile) {
    width: 90%;
    margin: 0 auto;
  }
  .spin {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    border: 0.15rem solid var(--white);
    border-top-color: var(--yellow);
    animation: spin 1s infinite linear;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .load-icon {
    transition: 200ms;
    font-size: 1.5rem;
  }
}

.sharp {
  opacity: 0.6;
  margin-right: 5px;
  transition: 200ms;
}

.title-settings-box {
  padding: 0 5px 0 5px;
  color: var(--white);
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: $tablet) {
    margin: 0;
  }
  @media screen and (max-width: $mobile) {
    width: 90%;
    margin: 0 auto;
  }
  .title {
    @media screen and (max-width: $large-screens) {
      font-size: 30px;
    }
    @media screen and (max-width: $small-screens) {
      font-size: 26px;
    }
    @media screen and (max-width: $tablet) {
      font-size: 16px;
      font-weight: 500;
    }
    &:hover .sharp {
      opacity: 1;
    }
  }
  .title-similar {
    @media screen and (max-width: $mobile) {
      font-size: 20px;
    }
    &:hover .sharp {
      opacity: 1;
    }
  }
  svg {
    cursor: pointer;
    font-size: 1.7rem;
    transform: rotate(-90deg);
  }
  svg {
    cursor: pointer;
    font-size: 1.7rem;
    transform: rotate(-90deg);
  }
}

.movies-box {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px !important;
  .trending {
    width: 100%;
    height: fit-content;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 14px !important;
    box-sizing: border-box;
    transition: 200ms;
    @media screen and (max-width: $mobile) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: 90%;
      margin: 0 auto;
    }
    .trending-movie-container {
      border-radius: 10px;
      overflow: hidden;
      min-width: 24%;
      list-style: none;
      height: fit-content;
      .trending-movie-box {
        width: 100%;
        height: 100%;
        display: flex;
        position: relative;
        align-items: flex-end;
        overflow: hidden;
        transition: 200ms;
        .ant-image,
        img {
          aspect-ratio: 10 / 16;
          object-fit: cover;
          height: 100%;
        }
        &:hover .trending-movie-info {
          left: 0;
          opacity: 1;
        }
        .trending-movie-info {
          box-sizing: border-box;
          width: 100%;
          height: 100%;
          padding: 10px;
          position: absolute;
          left: 50%;
          opacity: 0;
          color: var(--white);
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          background-color: $second-black;
          backdrop-filter: blur(3px);
          transition: 200ms;
          .vote-average {
            width: fit-content;
            padding: 4px 10px;
            gap: 10px;
            display: flex;
            align-items: center;
            border: 2px solid var(--yellow);
            border-radius: 20px;
            cursor: pointer;
            margin-top: 5px;
            &:hover {
              background-color: var(--yellow);
            }
          }
          .like-and-open {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            transition: 200ms;
            transition-delay: 300ms;
            .icon {
              border-radius: 10px;
              width: 20px;
              height: 20px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              padding: 5px;
              background-color: unset;
              border: 2px solid var(--yellow);
              &:hover {
                background-color: var(--yellow);
              }
            }
            a {
              width: 60%;
              text-decoration: unset;
              color: var(--white);
            }
            .play {
              border-radius: 10px;
              height: 20px;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 5px 15px;
              border: 2px solid var(--yellow);
              background-color: var(--yellow);
              cursor: pointer;
            }
          }
        }
      }
      .info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 10px;
        background-color: var(--yellow);
        margin: unset;
        color: var(--white);
        .text-anim {
          overflow: hidden;
          width: 75%;
          z-index: 100;
          @media screen and (max-width: $tablet) {
            width: 65%;
          }
          @media screen and (max-width: $mobile) {
            width: 65%;
          }
          p {
            white-space: nowrap;
          }
          .anim {
            white-space: nowrap;
            animation: text 6s linear infinite;
            animation-delay: 3s;
          }
          @keyframes text {
            0% {
              transform: translatex(100%);
            }
            100% {
              transform: translateX(-140%);
            }
          }
        }
        .text-anim.actor {
          width: 100%;
        }
        .vote {
          width: fit-content;
          display: flex;
          align-items: center;
          justify-content: right;
          @media screen and (max-width: $tablet) {
            width: 35%;
          }
          @media screen and (max-width: $mobile) {
            width: 35%;
          }
          svg {
            width: 50%;
            margin: 0 4px 0 0;
          }
          p {
            width: 50%;
          }
        }
      }
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.trendingNoItem {
  height: 150px;
  display: grid;
  grid-template-columns: auto;
  justify-content: center;
  align-items: center;
  color: var(--white);
  .noItems {
    display: flex;
    align-items: center;
    flex-direction: column;
    svg {
      font-size: 3rem;
    }
    p {
      opacity: 0.5;
    }
  }
}

.actor-name {
  background-color: var(--yellow);
  padding: 5px;
  color: var(--white);
  width: 100%;
}

.smallText {
  @media screen and (max-width: 900px) {
    font-size: 12px;
  }
  @media screen and (min-width: 490px) and (max-width: 700px) {
    font-size: 10px;
  }
}
