.load-box-movie {
    box-sizing: border-box;
    width: 100%;
    height: 500px;
    margin-top: 80px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    background-color: rgba(0, 0, 0, 0.02);
    @media screen and (max-width: "992px") {
        height: 400px;
    }
    @media screen and (max-width: "768px") {
        display: flex;
        flex-direction: column;
        height: 540px;
        background-color: transparent;
    }
    .right {
        box-sizing: border-box;
        width: 20%;
        height: 100%;
        padding: 30px 0 30px 30px;
        @media screen and (max-width: "1100px") {
            width: 30%;
        }
        @media screen and (max-width: "768px") {
            width: 100%;
            padding: 0;
        }
        .image {
            border-radius: 10px;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.06);
            overflow: hidden;
            &::before {
                content: "";
                width: 100%;
                height: 100%;
                background: linear-gradient(90deg, rgba(0, 0, 0, 0.06) 25%, rgba(0, 0, 0, 0.10) 37%, rgba(0, 0, 0, 0.06) 63%);
                display: block;
                animation: 1200ms skeleton linear infinite;
                animation-timing-function: ease;
            }
            @keyframes skeleton {
                0% {
                    transform: translateX(-120%);
                }
                100% {
                    transform: translateX(120%);
                }
            }
        }
    }
    .left {
        box-sizing: border-box;
        width: 80%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 30px 30px 30px 0;
        @media screen and (max-width: "1100px") {
            width: 70%;
        }
        @media screen and (max-width: "768px") {
            width: 100%;
            padding: 0 20px 20px 20px;
        }
        .big-text {
            border-radius: 10px;
            overflow: hidden;
            width: 200px;
            height: 48px;
            background-color: rgba(0, 0, 0, 0.06);
            @media screen and (max-width: "768px") {
                width: 40%;
            }
            &::before {
                content: "";
                width: 100%;
                height: 100%;
                background: linear-gradient(90deg, rgba(0, 0, 0, 0.06) 25%, rgba(0, 0, 0, 0.10) 37%, rgba(0, 0, 0, 0.06) 63%);
                display: block;
                animation: 1200ms skeleton linear infinite;
                animation-timing-function: ease;
            }
        }
        .small-text {
            border-radius: 10px;
            overflow: hidden;
            width: 400px;
            height: 28px;
            background-color: rgba(0, 0, 0, 0.06);
            @media screen and (max-width: "768px") {
                width: 90%;
            }
            &::before {
                content: "";
                width: 100%;
                height: 100%;
                background: linear-gradient(90deg, rgba(0, 0, 0, 0.06) 25%, rgba(0, 0, 0, 0.10) 37%, rgba(0, 0, 0, 0.06) 63%);
                display: block;
                animation: 1200ms skeleton linear infinite;
                animation-timing-function: ease;
            }
        }
        ul {
            display: flex;
            flex-direction: row;
            gap: 10px;
            li {
                border-radius: 10px;
                width: 38px;
                height: 38px;
                list-style: none;
                background-color: rgba(0, 0, 0, 0.06);
                overflow: hidden;
                &::before {
                    content: "";
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(90deg, rgba(0, 0, 0, 0.06) 25%, rgba(0, 0, 0, 0.10) 37%, rgba(0, 0, 0, 0.06) 63%);
                    display: block;
                    animation: 1200ms skeleton linear infinite;
                    animation-timing-function: ease;
                }
            }
        }
    }
}