$white: #fff;
$black: #343434;
$mobile: 480px;
$tablet: 768px;
$small-screens: 992px;
$large-screens: 1200px;

.class-for-actors {
    @media screen and (max-width: $small-screens) {
        height: 400px;
    }
    @media screen and (max-width: $tablet) {
        height: 540px;
    }
    @media screen and (max-width: 600px) {
        height: 560px;
    }
    @media screen and (max-width: 510px) {
        height: 670px;
    }
    @media screen and (max-width: $mobile) {
        height: 560px;
    }
    @media screen and (max-width: 450px) {
        height: 580px;
    }
    @media screen and (max-width: 415px) {
        height: 600px;
    }
}

.actors-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media screen and (max-width: $tablet) {
        width: 90%;
        padding: 0 20px;
    }
    .actors-title {
        margin: 20px 0 20px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: white;
        @media screen and (max-width: $mobile) {
            margin: 10px 0px;
        }
        .title {
            @media screen and (max-width: $mobile) {
                font-size: 20px;
            }
            &:hover .sharp {
                opacity: 1;
            }
        }
    }
    .slider-buttons {
        display: flex;
        flex-direction: row;
        gap: 10px;
        .buttonSlider {
            border-radius: 10px;
            padding: 5px;
            margin: 0 0 0 auto;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #ffffff39;
            backdrop-filter: blur(5px);
            cursor: pointer;
            color: white;
            font-size: 1.5rem;
        }
    }
    .actor {
        border-radius: 10px;
        width: 100%;
        height: 100%;
        display: flex !important;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        overflow: hidden;
        .movie-photo {
            width: 100% !important;
            height: 100%;
        }
        p {
            width: 100%;
            padding: 5px;
            background-color: var(--yellow);
            color: $white;
            cursor: pointer;
        }
    }
    .no-photo {
        width: 100%;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: $white;
        .icon {
            font-size: 3rem;
            color: var(--yellow);
            font-weight: 500;
        }
    }
}