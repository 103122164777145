$white: #fff;
$black: #343434;
$mobile: 480px;
$tablet: 768px;
$small-screens: 992px;
$large-screens: 1200px;

.ant-skeleton-title {
    height: 100px !important;
    border-radius: 0 !important;
    width: 100% !important;
}

.ads {
    width: 100vw;
    position: relative;
    z-index: 900;
    height: 500px;
    overflow: hidden;
    @media screen and (max-width: $small-screens) {
        height: 400px;
    }
    @media screen and (max-width: $tablet) {
        height: 300px;
    }
    @media screen and (max-width: $mobile) {
        height: 200px;
    }
    .bg-image {
        width: 100%;
        height: 500px;
        margin: 0 auto;
        background-position: center;
        background-size:cover;
        background-repeat: no-repeat;
        color: $white;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        position: absolute;
        left: 0;
        transition: 400ms;
        opacity: 0;
        &.activeMovie {
            opacity: 1;
            transform: translateX(0);
        }
        &.lastMovie {
            transform: translateX(-100%);
        }
        &.nextMovie {
            transform: translateX(100%);
        }
        @media screen and (max-width: $small-screens) {
            width: 100%;
            height: 400px;
        }
        @media screen and (max-width: $tablet) {
            width: 100%;
            height: 300px;
        }
        @media screen and (max-width: $mobile) {
            width: 100%;
            height: 200px;
        }
        .top-popular-home {
            width: 60%;
            @media screen and (max-width: $large-screens) {
                width: 60%;
            }
            @media screen and (max-width: $small-screens) {
                width: 70%;
            }
            @media screen and (max-width: $tablet) {
                width: 100%;
            }
            .play-and-next {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-direction: row;
                margin-bottom: 10px;
                div {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    button {
                        border-radius: 10px;
                        padding: 9px;
                        margin: 0 2px 0 10px;
                        border: none;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: #ffffff39;
                        backdrop-filter: blur(5px);
                        cursor: pointer;
                        color: $white;
                        font-size: 1.5rem;
                    }
                    .play-icon {
                        border-radius: 10px;
                        margin: 0 0 0 10px;
                        @media screen and (max-width: $large-screens) {
                            margin: 0 0 0 10px;
                        }
                        @media screen and (max-width: $small-screens) {
                            margin: 0 0 0 10px;
                        }
                        @media screen and (max-width: $tablet) {
                            margin: 0 10px 0 10px;
                        }
                    }
                }
                .play-watch-now {
                    border-radius: 10px;
                    width: 120px;
                    height: 42px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: var(--yellow);
                    cursor: pointer;
                    text-decoration: none;
                    color: $white;
                }
            }
            .info-box {
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
                @media screen and (max-width: $tablet) {
                    border-radius: 0;
                }
                height: 100px;
                padding: 10px;
                background-color: #ffffff39;
                backdrop-filter: blur(5px);
                overflow: hidden;
                @media screen and (max-width: $large-screens) {
                    height: 100px;
                }
                @media screen and (max-width: $small-screens) {
                    height: 60px;
                }
                @media screen and (max-width: $tablet) {
                    height: 30px;
                }
                .title-vote-box {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 10px;
                    h1 {
                        font-size: 30px;
                        gap: 20px;
                        @media screen and (max-width: $large-screens) {
                            font-size: 30px;
                        }
                        @media screen and (max-width: $small-screens) {
                            font-size: 26px;
                        }
                        @media screen and (max-width: $tablet) {
                            font-size: 20px;
                        }
                    }
                    .vote-average {
                        padding: 4px 10px;
                        gap: 10px;
                        display: flex;
                        align-items: center;
                        background-color: var(--yellow);
                        border-radius: 20px;
                        cursor: pointer;
                    }
                }
                .info-release {
                    text-transform: uppercase;
                    display: flex;
                    flex-direction: row;
                    @media screen and (max-width: $large-screens) {
                        display: flex;
                    }
                    @media screen and (max-width: $small-screens) {
                        display: flex;
                    }
                    @media screen and (max-width: $tablet) {
                        display: none;
                    }
                    span {
                        margin-left: 5px;
                        @media screen and (max-width: $large-screens) {
                            display: block;
                        }
                        @media screen and (max-width: $small-screens) {
                            display: block;
                        }
                        @media screen and (max-width: $tablet) {
                            display: none;
                        }
                    }
                }
                .info-overview {
                    margin-top: 6px;
                    text-transform: unset;
                    display: block;
                    @media screen and (max-width: $small-screens) {
                        display: none;
                    }
                    @media screen and (max-width: $tablet) {
                        display: none;
                    }
                }
            }
        }
    }
}