.ant-drawer .ant-drawer-content {
    background-color: var(--black);
    color: var(--white);
}

.ant-drawer .ant-drawer-close {
    color: var(--white);
    opacity: 0.5;
    transition: 200ms;
    &:hover {
        color: var(--white);
        opacity: 1;
    }
}

.ant-drawer .ant-drawer-header {
    border-bottom: 1px solid rgba(5, 5, 5, 0.151);
}

.ant-drawer .ant-drawer-title {
    color: var(--white);
}

.ant-btn {
    border-radius: 4px;
}

.ant-drawer-content-wrapper {
    height: 550px !important;
}

.events-box {
    width: 100%;
    position: relative;
    margin-top: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    .events {
        border-radius: 4px;
        font-size: 1.7rem;
        cursor: pointer;
        margin: 0 10px 0 0;
        background-color: var(--yellow);
        padding: 5px;
    }
}