$yellow-opacity: #e6b41e33;
$mobile: 480px;

.deletePopover {
    display: flex;
    gap: 10px;
    button {
        padding: 4px 8px;
        color: red;
        border: none;
        background-color: transparent;
        cursor: pointer;
        border-radius: 4px;
        &:nth-child(1) {
            background-color: var(--yellow);
            color: var(--white);
        }
    }
}

.tab-box {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin: 0 0 10px 0;
    .tab {
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 6px;
        padding: 4px;
        background-color: #252525;
        border: 2px solid var(--yellow);
        color: var(--white);
        cursor: pointer;
        font-size: 16px;
        transition: 200ms;
        svg {
            font-size: 20px;
        }
    }
    .tab.active {
        background-color: var(--yellow);
    }
}


.container {
    .settings-container {
        width: fit-content;
        @media screen and (max-width: $mobile) {
            width: 90%;
            margin: 0 auto;
        }
        .set-title {
        color: var(--white);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        h1 {
            font-size: 20px;
            font-weight: 600;
            &:hover span{
                opacity: 100%;
            }
            span {
                opacity: 50%;
                margin-right: 10px;
            }
        }
    }
    .info {
        color: var(--white);
        span {
            background-color: rgba(255, 255, 255, 0.096);
            cursor: pointer;
        }
    }
    .settings {
        color: var(--white);
        li {
            margin-bottom: 20px;
            list-style: none;
            .text-info {
                margin: 6px 0 4px 0;
            }
            .signOut {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 10px;
                svg {
                    color: var(--white);
                    font-size: 1.2rem;
                    opacity: .5;
                    transform: 200ms;
                    cursor: pointer;
                    &:hover {
                        opacity: 1;
                    }
                }
            }
            .delete {
                border-radius: 4px;
                padding: 5px;
                width: fit-content;
                background-color: rgba(255, 255, 255, 0.11);
                color: red;
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 10px;
            }
            .user-photo-settings {
                box-sizing: border-box;
                display: flex;
                flex-direction: row;
                .avatar {
                    border-radius: 10px;
                    cursor: pointer;
                    width: 70px;
                    height: 70px;
                    border: 2px solid var(--yellow);
                    background-color: var(--yellow);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .progresss {
                        animation: spin 1.3s infinite linear;
                    }
                    img {
                        border-radius: 10px;
                        aspect-ratio: 4 / 4;
                    }
                }
                .form {
                    width: 74px;
                    height: 74px;
                    position: relative;
                    left: -74px;
                    display: flex;
                    flex-direction: row;
                    transition: 200ms ease;
                    transform: scale(.8);
                    opacity: 0;
                    z-index: 10000;
                    &:hover {
                        transform: scale(1);
                        opacity: 1;
                    }
                    #upload {
                        display: none;
                    }
                    label {
                        border-radius: 10px;
                        transition: 200ms;
                        position: absolute;
                        width: 74px;
                        height: 74px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: 0;
                        color: var(--white);
                        cursor: pointer;
                        background-color: rgba(0, 0, 0, 0.33);
                        svg {
                            font-size: 1.5rem;
                        }
                    }
                }
            }
            .name {
                display: flex;
                flex-direction: column;
                gap: 10px;
                div {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 10px;
                    p {
                        span {
                            opacity: .5;
                        }
                    }
                    input {
                        border-radius: 4px !important;
                        display: inline;
                        box-sizing: border-box;
                        width: 190px;
                        height: 32px;
                        outline: none;
                        background-color: #252525;
                        padding: 4px;
                        border: 2px solid var(--white);
                        border-radius: 0;
                        font-size: 16px;
                        color: var(--white);
                        transition: 200ms;
                        &:focus {
                            border: 2px solid var(--yellow);
                        }
                    }
                    button {
                        border-radius: 4px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 4px 12px;
                        height: 32px;
                        border: none;
                        color: var(--white);
                        background-color: var(--yellow);
                        cursor: pointer;
                        transition: 200ms;
                    }
                }
            }
            .setting-title {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 10px;
                h3 {
                    font-size: 20px;
                    font-weight: 600;
                }
                &:hover svg {
                    transform: rotate(360deg);
                }
                svg {
                    transition: 300ms;
                    cursor: pointer;
                    font-size: 1.2rem;
                }
            }
            .functions {
                display: flex;
                flex-direction: column;
                gap: 10px;
            }
            .theme {
                display: flex;
                flex-direction: row;
                margin-top: 10px;
                .radio-box {
                    input{
                        display: none;
                        &:checked + label {
                            border: 4px solid var(--white);
                        }
                    }
                    label {
                        box-sizing: border-box;
                        cursor: pointer;
                        display: block;
                        width: 40px;
                        height: 40px;
                        border: 2px solid transparent;
                    }
                }
            }
        }
    }
    }
    
}