.cencored-content {
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: var(--white);
    a {
        color: unset;
    }
    .hand-icon {
        font-size: 3rem;
        color: var(--yellow);
    }
    .limited-content {
        font-weight: 500;   
    }
}