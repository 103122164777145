.load-box-photo {
    width: 80%;
    height: 250px;
    margin: auto;
    margin-bottom: 50px;
    @media screen and (max-width: "1200px") {
        width: 90%;
        margin: auto;
    }
    .title {
        width: 100%;
        height: 48px;
        margin: 20px 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .big-text {
            border-radius: 10px;
            overflow: hidden;
            width: 200px;
            height: 38px;
            background-color: rgba(0, 0, 0, 0.06);
            &::before {
                content: "";
                width: 100%;
                height: 100%;
                background: linear-gradient(90deg, rgba(0, 0, 0, 0.06) 25%, rgba(0, 0, 0, 0.10) 37%, rgba(0, 0, 0, 0.06) 63%);
                display: block;
                animation: 1200ms skeleton linear infinite;
                animation-timing-function: ease;
            }
            
        }
        ul{
            display: flex;
            flex-direction: row;
            gap: 10px;
            li {
                border-radius: 10px;
                overflow: hidden;
                width: 38px;
                height: 38px;
                list-style: none;
                background-color: rgba(0, 0, 0, 0.06);
                &::before {
                    content: "";
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(90deg, rgba(0, 0, 0, 0.06) 25%, rgba(0, 0, 0, 0.10) 37%, rgba(0, 0, 0, 0.06) 63%);
                    display: block;
                    animation: 1200ms skeleton linear infinite;
                    animation-timing-function: ease;
                }
            }
        }
    }
    .photo-box {
        width: 100%;
        height: 200px;
        ul {
            display: flex;
            flex-direction: row;
            gap: 20px;
            width: 100%;
            height: 100%;
            @media screen and (max-width: "992px") {
                :nth-child(1) {
                    display: none;
                }
            }            
            @media screen and (max-width: "480px") {
                :nth-child(2), :nth-child(3) {
                    display: none;
                }
            }
            li {
                border-radius: 10px;
                overflow: hidden;
                width: 25%;
                height: 100%;
                list-style: none;
                background-color: rgba(0, 0, 0, 0.06);            
                @media screen and (max-width: "992px") {
                    width: 33%;
                    height: 150px;
                }
                @media screen and (max-width: "480px") {
                    width: 100%;
                    height: 150px;
                }
                &::before {
                    content: "";
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(90deg, rgba(0, 0, 0, 0.06) 25%, rgba(0, 0, 0, 0.10) 37%, rgba(0, 0, 0, 0.06) 63%);
                    display: block;
                    animation: 1200ms skeleton linear infinite;
                    animation-timing-function: ease;
                }
            }
        }
    }
}