$yellow-opacity: #e6b41e33;
$mobile: 480px;
$tablet: 768px;

.right-info {
    h1 {
        @media screen and (max-width: $mobile) {
            font-size: 20px;
        }
    }
    .right-info-small {
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        p {
            border-radius: 4px;
            background-color: var(--yellow);
            padding: 2px;
        }
        @media screen and (max-width: $mobile) {
            display: flex;
            flex-direction: column;
        }
    }
    .right-overview-box {
        margin-top: 10px;
        width: 80%;
        @media screen and (max-width: $tablet) {
            width: 100%;
        }
    }
    .events-box {
        width: 100%;
        position: relative;
        margin-top: 10px;
        box-sizing: border-box;
        .events {
            border-radius: 4px;
            font-size: 1.7rem;
            cursor: pointer;
            margin: 0 10px 0 0;
            background-color: var(--yellow);
            padding: 5px;
        }
    }
}