$second-black: #2e2e2e71;
$white: #fff;
$black: #343434;
$yellow-opacity: #e6b41e33;
$mobile: 480px;
$tablet: 768px;
$small-screens: 992px;
$large-screens: 1200px;

.filter-container {
    .media-type {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 5px;
        .option {
            p {
                text-transform: uppercase !important;
                font-size: 4rem;
            }
        }
    }
    .reset {
        background-color: unset;
        border: none;
        position: relative;
        top: 4px;
        left: 78%;
        cursor: pointer;
        color: var(--yellow);
    }
}