$black-opacity: #343434b3;
$yellow-opacity: #e6b41e59;
$mobile: 480px;
$tablet: 768px;
$small-screens: 992px;
$large-screens: 1200px;

.class-for-actors {
    @media screen and (max-width: $small-screens) {
        height: 400px;
    }
    @media screen and (max-width: $tablet) {
        height: 540px;
    }
    @media screen and (max-width: 600px) {
        height: 560px;
    }
    @media screen and (max-width: 510px) {
        height: 670px;
    }
    @media screen and (max-width: $mobile) {
        height: 560px;
    }
    @media screen and (max-width: 450px) {
        height: 580px;
    }
    @media screen and (max-width: 415px) {
        height: 600px;
    }
}

.movies-open-media {
    @media screen and (max-width: $tablet) {
        background-image: unset !important;
    }
    @media screen and (max-width: $mobile) {
        display: flex;
        flex-direction: column;
    }
}

:where(.css-dev-only-do-not-override-1i9hnpv).ant-image .ant-image-mask {
    backdrop-filter: blur(5px);
    opacity: 0;
}
:where(.css-dev-only-do-not-override-1i9hnpv).ant-image .ant-image-mask:hover {
    opacity: 1;
}

:where(.css-dev-only-do-not-override-1i9hnpv).ant-image {
    width: 100%;
}

.movie-info-overflow {
    overflow:unset !important;
}

:where(.css-1i9hnpv).ant-image {
    width: 100%;
}

.opened-movie-backdrop {
    width: 100%;
    height: 100%;
    padding: 30px;
    display: flex;
    align-items: center;
    flex-direction: row;
    box-sizing: border-box;
    background-color: $yellow-opacity;
    backdrop-filter: blur(5px);
    gap: 20px;
    position: absolute;
    @media screen and (max-width: $small-screens) {
        display: flex;
        flex-direction: row;
    }
    @media screen and (max-width: $tablet) {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        background-color: unset;
        padding: 0;
    }
    @media screen and (max-width: $mobile) {
        display: flex;
        flex-direction: column;
        align-items: baseline;
    }
    .left-info {
        width: auto;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        z-index: 2000;
        @media screen and (max-width: $tablet) {
            width: 100%;
        }
        @media screen and (max-width: $mobile) {
            width: 100%;
        }
        .poster-image-box {
            aspect-ratio: 10 / 16;
            border-radius: 10px;
            height: 100%;
            display: flex;
            overflow: hidden;
            cursor: pointer;
            @media screen and (max-width: $tablet) {
                border-radius: 0;
                display: flex;
                align-items: center;
            }
            .poster-image {
                height: 100%;
                cursor: pointer;
            }
            .open-image-expand {
                width: 100%;
                height: 100%;
                background-color: $black-opacity;
                backdrop-filter: blur(5px);
                position: absolute;
                left: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 10px;
                transition: 200ms;
                opacity: 0;
                visibility: hidden;
                transform: scale(.9);
                p {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    .expand-icon {
                        font-size: 1.7rem;
                    }
                }
            }
            &:hover .open-image-expand{
                opacity: 1;
                visibility: visible;
                transform: scale(1);
            }
        }
        .open-image {
            width: 100%;
            height: 100%;
            background-color: $black-opacity;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 10px;
            cursor: pointer;
            display: none;
            transition: 200ms;
            @keyframes open-image {
                0% {
                    opacity: 0;
                    transform: scale(.8);
                }
                100% {
                    opacity: 1;
                    transform: scale(1);
                }
            }
        }
    }
    .right-info {
        width: 70%;
        height: 100%;
        @media screen and (max-width: $tablet) {
            width: 90%;
            height: fit-content;
            padding: 0 20px;
        }
    }
}