$yellow-opacity: #e6b41e33;
$mobile: 480px;
$tablet: 768px;
$small-screens: 992px;
$large-screens: 1200px;

.class-for-actors {
    @media screen and (max-width: $small-screens) {
        height: 400px;
    }
    @media screen and (max-width: $tablet) {
        height: 540px;
    }
    @media screen and (max-width: 600px) {
        height: 560px;
    }
    @media screen and (max-width: 510px) {
        height: 670px;
    }
    @media screen and (max-width: $mobile) {
        height: 560px;
    }
    @media screen and (max-width: 450px) {
        height: 580px;
    }
    @media screen and (max-width: 415px) {
        height: 600px;
    }
}

.actors-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media screen and (max-width: $tablet) {
        width: 90%;
        padding: 0 20px;
    }
    .actors-title {
        margin: 20px 0 20px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: white;
        @media screen and (max-width: $mobile) {
            margin: 10px 0px;
        }
        .title {
            @media screen and (max-width: $mobile) {
                font-size: 20px;
            }
            &:hover .sharp {
                opacity: 1;
            }
        }
    }
    .slider-buttons {
        display: flex;
        flex-direction: row;
        gap: 10px;
        .buttonSlider {
            padding: 5px;
            margin: 0 0 0 auto;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #ffffff39;
            backdrop-filter: blur(5px);
            cursor: pointer;
            color: var(--white);
            font-size: 1.5rem;
        }
    }
    .actorLink {
        text-decoration: none;
    }
    .actor {
        width: 100%;
        height: 100%;
        display: flex !important;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        overflow: hidden;
        img {
            cursor: pointer;
        }
        .actor-name-box {
            width: 100%;
            background-color: var(--yellow);
            p {
                width: fit-content;
                padding: 5px;
                color: var(--white);
                cursor: pointer;
                white-space: nowrap;
            }
            .anim {
                width: fit-content;
                animation: text 6s linear infinite;
                animation-delay: 3s;
            }
            @keyframes text {
                0% {
                    transform: translatex(100%);
                }
                100% {
                    transform: translateX(-130%);
                }
            }
        }
    }
}